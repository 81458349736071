<template>
  <div class="my-5 mx-5">
    <div class="col-lg-6 col-md-6 mx-auto mb-5">
      <card class="card-lock card-white text-center mt-0 pt-5">
        <div class="card-body">
          <h3 class="card-title">{{ $t("pageNotFound") }}</h3>
        </div>
        <div class="card-footer">
          <h4>{{ $t("tryGoToHomePage") }} <a href="/">{{ appName }}</a></h4>
        </div>
      </card>
    </div>

    <section class="col col-lg-6 mx-auto">
      <search-bar class="mx-auto  mb-5 mx-2" />
    </section>
  </div>
</template>
<script>
import { Card, SearchBar } from "@/components/index";

export default {
  data: () => {
    return {
      appName: process.env.VUE_APP_AS_NAME,
      selectedStoreCategory: null,
    }
  },
  methods: {
    async getItems(page, filters) {
      if(this.pagesLoad.includes(page)) return;

      try {
        this.isLoadingStores= true;
        let route = `${this.route}?page=${page}`;
        let params = {};

        if(filters) {
          route = this.route;
          params = {...filters}
        }
        
        let response = await this.axios.get(route, { params } );
        if (response && response.data.status === "success") {
          this.pagesLoad.push(page);
          
          if(response.data.data.stores) {
            const data = response.data.data.stores.data;
            this.stores = filters? data : [...this.stores, ...data];
            data.forEach((store) => (store.id = store.slug));
  
            this.currentPage = response.data.data.stores.current_page;
            this.pagination.perPage = response.data.data.stores.per_page;
            this.pagination.lastPage = response.data.data.stores.last_page;
            this.pagination.totalItems = response.data.data.stores.total;
          }
        }
      } catch (error) {
       console.log(error); 
      }
      this.isLoadingStores= false;
    },
    setSelectedTab(tab) {
      /* if(!this.selectedStoreCategory) {
        this.$router.push({ name: 'storeListFiltered', params: { slug: tab.code } });
        return;
      }
      
      const isSameCategory = this.selectedStoreCategory.code === tab.code;
      if(isSameCategory) {
        this.$router.push({ name: 'storeList'});
      } else {
        this.$router.push({ name: 'storeListFiltered', params: { slug: tab.code } });
      } */
    },
    getStoresServices(filters, serviceName) {
      this.pagesLoad = [];
      this.stores = [];
      this.getItems(null, filters);
      if(serviceName) this.serviceName = serviceName;
    },
  },
  components: {
    Card,
    SearchBar
  }
};
</script>
<style scoped>
.not-found-container {
  min-height:80%;
}
</style>